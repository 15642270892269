import styled from 'styled-components';
import { Heading } from '@nintendo-of-america/component-library';
import { Constrained } from '@local/components';

export const Wrap = styled(Constrained)`
  margin-top: ${({ theme }) => theme.spacing[40]};
  margin-bottom: ${({ theme }) => theme.spacing[40]};
`;

export const StyledHeading = styled(Heading)`
  margin-bottom: ${({ theme }) => theme.spacing[16]};

  ${({ theme }) => theme.mediaQuery.desktop} {
    margin-bottom: ${({ theme }) => theme.spacing[24]};
  }
`;
